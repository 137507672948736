
<script setup>
    import { ref, watch, computed, onMounted } from "vue";
    import { hapticsImpact } from "./utils.ts";

    import MSearch from "./components.Search.vue";
    import MContainer from "./components.Container.vue";
    import MContent from "./components.Content.vue";

    const props = defineProps(["filter"]);

    const data = ref([]);
    const search = ref("");
    const loading = ref(true);
    const values = ref([]);

    const isRadio = computed(() => props.filter.field.getItem().distinctType === "bit");

    async function update() {
        loading.value = true;
        
        values.value = await props.filter.field.getDistinctValues(search.value);

        loading.value = false;
    }

    onMounted(async () => {
        update();
    });

    function onClick(value) {
        if (isRadio.value && !value.selected) {
            values.value.forEach(e => e.selected = false);
        }
        value.selected = !value.selected;
        props.filter.field.setTempValue(values.value);
        hapticsImpact("SelectionClick");
    }

    function isChanged() {
        return data.value.filter(e => e.selected !== e._selected).length > 0;
    }
    
    function apply() {
        props.filter.selectField(null);
        hapticsImpact("MediumImpact");
    }

    watch(() => props.filter.field, () => {
        console.log("watch");
    /*
        if (!props.filter.field && !loading.value) {
            console.log("APPLY");
            props.filter.field.setValues(data.value.filter(e => e.selected).map(e => e.value));
            props.filter.updateFilterString();
        }
        */
        if (props.filter.field) {
            console.log("props.filter.field changed");
            update();
        }
    });
</script>

<template>
    <m-container :showSpinner="loading">
        <div class="mt-3 px-3">
            <m-search v-model="search" @search="update" />
        </div>
        <m-content>
            <div class="mt-2 px-3 pb-3">
                <template v-if="values.length">
                    <template v-for="(value, index) in values">
                        <a class="d-flex align-items-center list-item" :class="{ 'border-top': index > 0 }" style="padding: 0.75rem 1.00rem; gap: 0.5rem; color: black; font-size: 0.9em; text-decoration: none;" @click="onClick(value)">
                            <div>
                                <template v-if="isRadio">
                                    <template v-if="value.selected">
                                        <i class="fas fa-dot-circle text-primary" style="font-size: 1.5em;" />
                                    </template>
                                    <template v-else>
                                        <i class="fal fa-circle text-muted" style="font-size: 1.5em;" />
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-if="value.selected">
                                        <i class="fas fa-check-square text-primary" style="font-size: 1.5em;" />
                                    </template>
                                    <template v-else>
                                        <i class="fal fa-square text-muted" style="font-size: 1.5em;" />
                                    </template>
                                </template>
                            </div>
                            <template v-if="value.count !== -1">
                                <div class="text-muted">
                                    ({{ value.count }})
                                </div>
                            </template>
                            <div>
                                {{ value.name }}
                            </div>
                        </a>
                    </template>
                </template>
                <template v-else-if="!loading">
                    <div class="p-3 d-flex justify-content-center text-muted">
                        {{ $t("No results found") }}
                    </div>
                </template>
            </div>
        </m-content>
        <transition name="raise">
            <template v-if="filter.field.isTempChanged()">
                <div class="p-3 d-flex">
                    <button class="flex-grow-1 btn btn-primary" @click="apply">
                        {{ $t("Apply") }}
                    </button>
                </div>
            </template>
        </transition>
        <!--
        <div class="p-3 d-flex apply-button" :class="{ 'show': isChanged() }">
            <button class="flex-grow-1 btn btn-primary" @click="apply">
                {{ $t("Apply") }}
            </button>
        </div>
        -->
    </m-container>
</template>

<style scoped>
    .list-item {
        background-color: white;

        transition: all 200ms ease-in-out;

        &:active {
            transition: all 0ms;
            background-color: rgb(95%, 95%, 95%);
        }
    }

</style>
